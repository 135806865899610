/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Text from '@ingka/text';
import './Reports.css';
import { useDispatch } from 'react-redux';
import Pill from '@ingka/pill';
import IcRightArrow from '@ingka/ssr-icon/paths/arrow-right';
import { appRoutes } from '../../../common/constants/constant';
import { setLoader } from '../../../store/reducers/create-proj-reducer';
import { offlineData } from '../../../common/configs/offlineData';
import { isIos, projectType } from '../../../common/services/commonService.js';
import { fectchUnitCode } from '../findComparison/comparisonList/ComparisonList';
import useCallApi from '../../../common/services/apiService.js';

export type Widget = {
  reportId: string;
  reportName: string;
  embedUrl: string;
}

export type Reports = {
  reportType: string;
  token: string;
  reportsDetails: Widget[];
}

const Reports = () => {
  const dispatch = useDispatch();
  const { post } = useCallApi();
  const [reports, setReports] = useState<Widget[]>([]);
  const onWidgetClick = (widget: Widget) => {
    window.open(`${appRoutes.report}?rId=${widget.reportId}`, '_blank', 'noreferrer');
  };

  const getReports = () => new Promise((resolve, reject) => {
    const payload = {
      userType: projectType(),
      unitCode: !isIos() ? fectchUnitCode() : undefined,
    };
    post('get-reports', payload, (data: Reports) => {
      resolve(data);
    }, (err: any) => reject(err));
  });

  useEffect(() => {
    dispatch(setLoader(true));
    getReports().then((data: any) => {
      offlineData.setItem('reports', data);
      setReports(data.reportsDetails);
    }).finally(() => {
      dispatch(setLoader(false));
    });
  }, []);

  return (
    <div className="reportsContainer">
      <Text className="reportsTitle">Reports</Text>
      <div className="reportsWidgetContainer">
        {reports?.length > 0 ? reports?.map((widget: Widget, index) => (
          <div
            key={widget.reportId}
            className="reportsWidget"
            role="button"
            tabIndex={index}
            onKeyDown={() => onWidgetClick(widget)}
            onClick={() => onWidgetClick(widget)}
          >
            <div>
              <Text className="reportsWidgetTitle">{widget?.reportName?.replaceAll('_', ' ')}</Text>
              {/* <Text className="reportsWidgetDesc">{widget?.reportDesc}</Text> */}
            </div>
            <Pill
              className="reportsPill"
              iconOnly
              label="Label"
              ssrIcon={IcRightArrow}
            />
          </div>
        )) : null}
      </div>
    </div>
  );
};

export default Reports;
