/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Cards from '../../../../common/components/card/Card';
import './Accepted.css';
import {
  isIos, isRu, isSu, isNullEmptyOrUndefined,
} from '../../../../common/services/commonService.js';
import {
  ProjectType, commonColumns, iosEndDate, ruSuAccepted,
} from '../Dashboard';
import DashboardList from '../../../../common/components/list/List';
import { useAppSelector } from '../../../../store/hooks';

interface AcceptedProps {
    acceptedProjects: any[],
    highlightDays: number | null;
    handleAcceptedProjectClick?: (value: any) => void,
    handleProjectClick?: (value: any, type: ProjectType) => void;
}

const Accepted = ({
  acceptedProjects,
  highlightDays,
  handleAcceptedProjectClick,
  handleProjectClick,
}: AcceptedProps) => {
  const [columnData, setColumnData] = useState<any[]>([]);
  const gridListFlag = useAppSelector((state) => state?.dashboardReducer?.gridListFlag);
  const showIosEndDate = (project: any) => {
    if (isIos()) {
      return false;
    }
    return (isNullEmptyOrUndefined(project.parentProjectEndDate) ? false
      : project.parentProjectEndDate);
  };

  const buildColumnData = () => {
    const tempColumnData = [...commonColumns];
    if (isIos() === true || isRu() === true) {
      tempColumnData.push(ruSuAccepted);
      setColumnData(tempColumnData);
    }
    if (isRu() === true) {
      tempColumnData.splice(4, 0, iosEndDate);
      setColumnData(tempColumnData);
    }
    if (isSu()) {
      setColumnData(tempColumnData);
    }
  };

  useEffect(() => {
    buildColumnData();
  }, []);

  const setHeight = () => {
    if (isSu()) {
      return '120px';
    }
    return '154px';
  };

  const setAcceptedCount = (project: any) => {
    if (isRu()) {
      return false;
    }
    if (isNullEmptyOrUndefined(project.accepted)) {
      return false;
    }
    return project.accepted;
  };

  return (
    <div>
      {acceptedProjects?.length > 0
        ? gridListFlag === true
          ? (
            <div className="projectsContentWrapper">
              {acceptedProjects?.map((project) => (
                <Cards
                  key={project.projectId}
                  cardKey={project.projectId}
                  name={project.projectName}
                  images={project?.itemImageUrl}
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={showIosEndDate(project)}
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  suAccepted={setAcceptedCount(project)}
                  highlightDays={highlightDays}
                  onClick={() => (
                    (!isRu())
                      ? handleProjectClick && handleProjectClick(project, ProjectType.ACCEPTED)
                      : handleAcceptedProjectClick && handleAcceptedProjectClick(project))}
                />
              ))}
            </div>
          ) : (
            <DashboardList
              projectData={acceptedProjects}
              columnData={columnData}
              onRowClick={(project) => (!isRu()
                ? handleProjectClick && handleProjectClick(project, ProjectType.ACCEPTED)
                : handleAcceptedProjectClick && handleAcceptedProjectClick(project))}
            />
          ) : null}
    </div>
  );
};

Accepted.defaultProps = {
  handleProjectClick: () => null,
  handleAcceptedProjectClick: () => null,
};

export default Accepted;
