/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import { useLocation } from 'react-router-dom';
import classes from './RenderEmpty.module.css';
import { useTranslate } from '../../services/translationService.js';
import { appRoutes } from '../../constants/constant';

const RenderEmpty = ({
  empText, icon, subText, smallType,
}: {
  empText: string;
  icon: any;
  subText?: boolean;
  smallType?:boolean;
}) => {
  const location = useLocation();
  const subtext1 = location.pathname === appRoutes.FC ? useTranslate('render.empty.fcSubText1')
    : useTranslate('render.empty.subtext1');
  const subtext2 = location.pathname === appRoutes.FC ? useTranslate('render.empty.fcSubText2')
    : useTranslate('render.empty.subtext2');
  const subtext3 = location.pathname === appRoutes.FC ? null : useTranslate('render.empty.subtext3');
  return (
    <div className={classes.emptyData}>
      <SSRIcon
        className={smallType
          ? classes.emptyDataIconSmall
          : classes.emptyDataIcon}
        paths={icon}
      />
      {' '}
      <div>
        <Text
          className={smallType ? classes.emptyMainTextSmall : classes.emptyMainText}
          headingSize="xl"
          tagName="h1"
        >
          <b>{empText}</b>
        </Text>
        {subText && (
          <div className={classes.emptySubText}>
            {subtext1}
            {' '}
            <br />
            {subtext2}
            {' '}
            <br />
            {subtext3}
          </div>
        )}
      </div>
    </div>
  );
};

RenderEmpty.defaultProps = {
  subText: false,
  smallType: false,
};
export default RenderEmpty;
