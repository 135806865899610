/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useEffect } from 'react';
import './App.module.css';
import {
  Route, Routes, useLocation,
}
  from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import CreateProject from './components/desktop/createProject/CreateProject';
import './common/configs/i18n';
import LoginComponent from './components/desktop/login/Login';
import AppBar from './common/components/appBar/AppBar';
import Dashboard from './components/desktop/dashboard/Dashboard';
import 'antd/dist/antd.min.css';
import InstantComparison from './components/desktop/instantComparison/InstantComparison';
import AppToast from './common/components/appToast/AppToast';
import InstantComparisonMobile from './components/mobile/instantComparisonMobile/InstantComparison';
import RetailUnitSelection from './components/mobile/initialSelection/retailUnitSelection/RetailUnitSelection';
import HfbSelection from './components/mobile/initialSelection/hfbSelection/HfbSelection';
import {
  isNullEmptyOrUndefined, notificationUrls, userData,
} from './common/services/commonService.js';
import DashboardMobile from './components/mobile/dashboardMobile/DashboardMobile';
import Loader from './common/components/loader/loader';
import OutboxComponent from './components/mobile/outbox/OutboxComponent';
import ProjectDetails from './components/mobile/projectsMobile/projectDetails/ProjectDetails';
import { appRoutes, userTypesData } from './common/constants/constant';
import SentAndClosedProjectDetails from './components/desktop/projectsDesktop/projectDetails/sentAndClosed/SentAndClosedProjectDetails';
import FindComparison from './components/desktop/findComparison/FindComparison';
import UnauthorizedPage from './common/components/unauthorizedPage/UnauthorizedPage';
import { fetchRetailUnits } from './common/services/commonApi.js';
import useCallApi from './common/services/apiService.js';
import QualityCheck from './components/desktop/qualityCheck/QualityCheck';
import Reports from './components/desktop/reports/Reports';
import Report from './components/desktop/reports/Report';

const ProtectedRoute = ({ component, ...args }:any) => {
  let Component;
  const userType = userData()?.isOfUserType;
  const userValid = userData()?.isValidUserAccessRole;
  const { isAuthenticated } = useAuth0();
  if (localStorage.getItem('loggedInOnce') && localStorage.getItem('loggedInOnce') === 'true'
  && localStorage.getItem('loggedOut') !== 'true' && isMobile) {
    Component = component;
  } else if (isAuthenticated === true
    && window.location.pathname !== appRoutes.home
    && (isNullEmptyOrUndefined(userType) || !userTypesData.includes(userType))
    && userValid === false) {
    Component = <UnauthorizedPage invalid />;
  } else if (isAuthenticated === true
    && window.location.pathname !== appRoutes.home
    && (isNullEmptyOrUndefined(userType) || !userTypesData.includes(userType))) {
    Component = <UnauthorizedPage invalid={false} />;
  } else Component = withAuthenticationRequired(component, args);
  return isNullEmptyOrUndefined(Component?.type) ? <Component /> : Component;
};

const App = () => {
  const { get } = useCallApi();
  const location = useLocation();

  useEffect(() => {
    if (window.performance.navigation.type === performance.navigation.TYPE_RELOAD && !isMobile) {
      fetchRetailUnits(get);
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        isMobile === true && registration.update();
      });
    }
  }, [location]);

  return (
    <Suspense fallback={<Loader manualLoader />}>
      <Routes>
        <Route
          path={appRoutes.home}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={LoginComponent} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.newProject}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={CreateProject} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.IC}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              {isMobile
                ? <ProtectedRoute component={InstantComparisonMobile} />
                : <ProtectedRoute component={InstantComparison} />}
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.dashboard}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              {isMobile
                ? <ProtectedRoute component={DashboardMobile} />
                : <ProtectedRoute component={Dashboard} />}
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.sentProjectDetails}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={SentAndClosedProjectDetails} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.qualityCheck}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              {!isMobile
                ? <ProtectedRoute component={QualityCheck} />
                : <UnauthorizedPage invalid={false} />}
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.reports}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              {!isMobile
                ? <ProtectedRoute component={Reports} />
                : <UnauthorizedPage invalid={false} />}
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.report}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              {!isMobile
                ? <ProtectedRoute component={Report} />
                : <UnauthorizedPage invalid={false} />}
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.outbox}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={OutboxComponent} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.RUselect}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={RetailUnitSelection} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.HFBselect}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={HfbSelection} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.projDetails}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={ProjectDetails} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.Unauthiorized}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <UnauthorizedPage invalid={false} />
            </Suspense>
        )}
        />
        <Route
          path={appRoutes.FC}
          element={(
            <Suspense fallback={<Loader manualLoader />}>
              <ProtectedRoute component={FindComparison} />
            </Suspense>
        )}
        />
      </Routes>
      <AppToast />
      <AppBar />
      <Loader />
    </Suspense>
  );
};

export default App;
