/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import RadioButton from '@ingka/radio-button';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import { DatePicker, DatePickerProps } from 'antd';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcCross from '@ingka/ssr-icon/paths/cross';
import React from 'react';
import moment, { Moment } from 'moment';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './TroStartDate.module.css';
import './TroStartDate.css';

interface TroStartDateProps {
  startDate: Moment | null;
  endDate: Moment | null;
  unknownStartDate: boolean;
  setUnknownStartDate: React.Dispatch<React.SetStateAction<boolean>>;
  setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>;
}

const TroStartDate = ({
  startDate, endDate, unknownStartDate,
  setUnknownStartDate, setStartDate, setEndDate,
}: TroStartDateProps) => {
  const onStartDateChange: DatePickerProps['onChange'] = (date, _dateString) => {
    if (date) {
      setStartDate(date);
      setUnknownStartDate(false);
    } else {
      setStartDate(null);
    }
  };

  return (
    <div className={styles.sectionWrapper}>
      <SectionTitle sectionTitle={useTranslate('inst.comp.restrictedOfferTimeStart')} />
      <Text
        className={styles.dateLabel}
        headingSize="s"
        tagName="p"
      >
        {useTranslate('tab.details.start_date')}
      </Text>
      <DatePicker
        inputReadOnly
        className={styles.dateInput}
        onChange={onStartDateChange}
        suffixIcon={<SSRIcon paths={IcDownArrow} className={styles.icon} />}
        placeholder={useTranslate('inst.comp.date')}
        value={startDate}
        allowClear
        clearIcon={<SSRIcon paths={IcCross} className={styles.icon} />}
        disabledDate={(d) => !d || d.isAfter(moment(endDate))}
        popupClassName="troStartDateDropdown"
      />
      <RadioButton
        className={styles.radioButton}
        id="rb_startDate"
        label={useTranslate('inst.comp.radioButtonText')}
        name="rb_startDate"
        value={useTranslate('inst.comp.radioButtonValue')}
        checked={unknownStartDate}
        disabled={!isNullEmptyOrUndefined(startDate)}
        onClick={() => {
          setStartDate(null);
          setUnknownStartDate(!unknownStartDate);
        }}
      />
    </div>
  );
};

export default TroStartDate;
