/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Search from '@ingka/search';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/search/dist/style.css';
import '@ingka/focus/dist/style.css';
import './SellingUnits.css';
import IcRU from '@ingka/ssr-icon/paths/location-pin';
import {
  Checkbox, ConfigProvider, Table,
} from 'antd';
import { ColumnGroupType, ColumnType, ColumnsType } from 'antd/es/table';
import Button from '@ingka/button';
import { useTranslate } from '../../../../common/services/translationService.js';
import styles from './SellingUnits.module.css';
import useCallApi from '../../../../common/services/apiService.js';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  createProj, prevProj, setLoader, setSU,
} from '../../../../store/reducers/create-proj-reducer';
import { offlineData } from '../../../../common/configs/offlineData';
import { RetailUnit } from '../retailUnitsTab/RetailUnits';
import { projStatus } from '../../../../common/constants/constant';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { Item } from '../items/Items';

export interface SellingUnit {
  key: React.Key;
  unitName: string;
  parentUnitCode: string;
  unitCode: string;
  isAdded: boolean;
}

export interface SuItemListData {
  SuCode: string;
  isActive: boolean;
  SuItemList: any[];
}

const SellingUnits = (props: any) => {
  const [sellingUnitsDisplay, setSellingUnitsDisplay] = useState<SellingUnit[]>([]);
  const [sellingUnits, setSellingUnits] = useState<SellingUnit[]>([]);
  const [selectedSu, setSelectedSu] = useState<SellingUnit[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [selectedItemData, setSelectedItemData] = useState<SuItemListData[]>([]);

  const suDetails:any = props?.suDetails;

  const projectStore = useAppSelector((state: any) => state.createProjReducer.createProjData);
  const prevProjectStore = useAppSelector((state: any) => state.createProjReducer.prevProjData);
  const dispatch = useAppDispatch();
  const { get, post } = useCallApi();
  const selectedSuInStore: SellingUnit[] = projectStore?.suTab?.selectedSu;

  const selectedItems: Item[] = projectStore?.itemsTab?.selectedItems || [];
  const newSelectedItems: Item[] = useAppSelector(
    (state: any) => state.createProjReducer.newItemSelected,
  ) || [];
  const isDraftProj = () => (props.projtType === projStatus.draft);
  const isAcceptedProj = () => (props.projtType === projStatus.accepted);
  const isEditProject = props?.isEditProject;

  const defaultColumns: ColumnsType<SellingUnit> = [
    {
      key: 1,
      title: useTranslate('tab.su.selling_unit'),
      dataIndex: 'unitCode',
      width: 100,
      className: 'suTableSuCode',
      fixed: 'left',
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: useTranslate('tab.su.selling_unit_name'),
      dataIndex: 'unitName',
      width: 160,
      className: 'suTableSuName',
      fixed: 'left',
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  const [columns, setColumns] = useState<ColumnsType<SellingUnit>>(defaultColumns);

  const handleCheckboxChangeFactory = (
    value: any,
    record: SellingUnit,
    item: Item,
  ) => () => {
    let itemData: SuItemListData[] = [];
    if (!isNullEmptyOrUndefined(selectedItemData) && selectedItemData?.length !== 0) {
      const dataCopy = JSON.parse(JSON.stringify(selectedItemData));
      const selSuData = dataCopy.find((val : any) => val.SuCode === record.unitCode);
      if (selSuData && !isNullEmptyOrUndefined(selSuData)) {
        if (selSuData.SuItemList.length !== 0) {
          selSuData.SuItemList.map((val:any, i:number) => {
            if (val.ItemNo === item.itemNo) {
              selSuData.SuItemList.splice(i, 1);
              if (selSuData.SuItemList?.length === 0) {
                const newSelectedRowKeys = selectedRowKeys.filter((key) => key !== record.key);
                setSelectedRowKeys(newSelectedRowKeys);
                const newSelectedItemData = dataCopy.filter(
                  (suInfo: any) => suInfo.SuCode !== record.unitCode,
                );
                const projData = ({
                  ...projectStore,
                  selectedSuItems: newSelectedItemData,
                });
                dispatch(createProj(projData));
              }
            } else {
              const duplicateItemFilter = selSuData.SuItemList?.length !== 0
                ? selSuData.SuItemList.filter((x : any) => x.ItemNo === item.itemNo) : [];
              duplicateItemFilter.length === 0 && selSuData.SuItemList.push(
                {
                  ItemNo: item.itemNo,
                  ItemType: item.itemType,
                },
              );
            }
            return null;
          });
        } else {
          selSuData.SuItemList.push({
            ItemNo: item.itemNo,
            ItemType: item.itemType,
          });
        }
        itemData = [selSuData];
      } else {
        itemData.push({
          SuCode: record.unitCode,
          isActive: true,
          SuItemList: [
            {
              ItemNo: item.itemNo,
              ItemType: item.itemType,
            },
          ],
        });
      }
    } else {
      itemData.push({
        SuCode: record.unitCode,
        isActive: true,
        SuItemList: [
          {
            ItemNo: item.itemNo,
            ItemType: item.itemType,
          },
        ],
      });
    }
    const filterSelectedItem = !isNullEmptyOrUndefined(selectedItemData)
      ? selectedItemData.filter((val: any) => !itemData.some(
        (i: any) => i?.SuCode === val?.SuCode,
      )) : [];
    const updatedItemData = itemData.filter((val) => val.SuItemList?.length !== 0);
    setSelectedItemData([...filterSelectedItem, ...updatedItemData]);
    const projData = ({
      ...projectStore, selectedSuItems: [...filterSelectedItem, ...updatedItemData],
    });
    dispatch(createProj(projData));
  };

  const handleCheckboxChecked = (record: SellingUnit, item: Item): boolean => {
    const filData = selectedItemData.find((val) => (val.SuCode === record.unitCode));
    if (filData && !isNullEmptyOrUndefined(filData)) {
      const checkData = filData.SuItemList.findIndex((val) => (val.ItemNo === item.itemNo));
      if (checkData !== -1) return true;
      return false;
    }
    return false;
  };

  const renderTitle = (item: Item) => (
    <div className="suColumnTitleWrapper">
      {}
      <br />
      <span>{item.itemName}</span>
      <br />
      <span className="suColumnItemNo">{item.itemNo}</span>
    </div>
  );

  const isCheckboxDisabled = (record: SellingUnit) => {
    const index = selectedRowKeys?.findIndex((su: any) => su === record.key);
    if (index !== -1) {
      return false;
    }
    return true;
  };

  const activeItems = selectedItems?.filter((item) => item?.isActive !== false);

  useEffect(() => {
    if (selectedItems?.length >= 0) {
      const newColumns: ColumnsType<SellingUnit> = defaultColumns;
      const dummyColumn: ColumnGroupType<SellingUnit> | ColumnType<SellingUnit> = {
        key: 999,
        title: '',
        dataIndex: 'dummyColumnToFixTableWidth',
        shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
        render: () => null,
      };
      activeItems.forEach((item, index) => {
        const column: ColumnGroupType<SellingUnit> | ColumnType<SellingUnit> = {
          key: index + 3,
          title: renderTitle(item),
          dataIndex: `item#${index}`,
          width: 200,
          shouldCellUpdate: () => true,
          render: (value: any, record: SellingUnit) => (
            <Checkbox
              onClick={() => {
                dispatch(setLoader(true));
                setTimeout(() => {
                  dispatch(setLoader(false));
                }, 50);
              }}
              checked={handleCheckboxChecked(record, item) === true}
              onChange={handleCheckboxChangeFactory(value, record, item)}
              disabled={isCheckboxDisabled(record)}
            />
          ),
        };
        newColumns.push(column);
      });
      if (activeItems.length < 5) {
        newColumns.push(dummyColumn);
      }
      setColumns(newColumns);
    }
  }, [selectedItems, selectedItemData, selectedRowKeys]);

  useEffect(() => {
    const newSelectedData: any = [];
    !isNullEmptyOrUndefined(selectedItemData) && selectedItemData.forEach((suInfo: any) => {
      const newSuItemList: any = [];
      suInfo?.SuItemList?.forEach((suItem: any) => {
        newSuItemList.push(suItem);
      });
      if (newSelectedItems?.length !== 0) {
        newSelectedItems.forEach((value: any) => newSuItemList.push({
          ItemNo: value.itemNo,
          ItemType: value.itemType,
        }));
      }
      const newSuObject = {
        SuCode: suInfo.SuCode,
        isActive: suInfo.isActive,
        SuItemList: newSuItemList,
      };
      newSelectedData.push(newSuObject);
    });
    !isNullEmptyOrUndefined(newSelectedData) && setSelectedItemData(newSelectedData);
  }, [selectedItems]);

  useEffect(() => {
    if (selectedSuInStore?.length !== selectedSu?.length) {
      const newSelectedKeys: React.Key[] = [];
      selectedSuInStore?.forEach((item) => newSelectedKeys.push(item.key));
      setSelectedRowKeys(newSelectedKeys);
      setSelectedSu(selectedSuInStore);
    }
  }, [selectedSuInStore]);

  const onSelect = (
    record?: SellingUnit,
    selected?: boolean,
    selectedRows?: any,
  ) => {
    const selectExist = selectedItemData.find((val) => val.SuCode === record?.unitCode);
    dispatch(setLoader(true));
    let currentSelectData : SuItemListData = { SuCode: '', SuItemList: [], isActive: false };
    if (selected === true) {
      let itemDataToChange = [...selectedItemData];
      if (selectExist && !isNullEmptyOrUndefined(selectExist)
      && selectExist.SuItemList?.length !== 0) {
        const selectIndex = itemDataToChange.findIndex((val) => val.SuCode === record?.unitCode);
        itemDataToChange[selectIndex] = { ...itemDataToChange[selectIndex], isActive: true };
        setSelectedItemData(itemDataToChange);
      } else {
        const data = activeItems?.map((val) => (
          {
            ItemNo: val.itemNo,
            ItemType: val.itemType,
          }
        ));
        currentSelectData = { SuCode: record?.unitCode || '', isActive: true, SuItemList: data };
        setSelectedItemData([...selectedItemData, currentSelectData]);
        itemDataToChange = [...selectedItemData, currentSelectData];
      }
      const projData = ({
        ...projectStore,
        suTab: { selectedSu: selectedRows || [] },
        selectedSuItems: itemDataToChange,
      });
      dispatch(createProj(projData));
    } else {
      let itemDataToChange = [...selectedItemData];
      if (selectExist && !isNullEmptyOrUndefined(selectExist)
      && selectExist.SuItemList?.length !== 0) {
        const selectIndex = itemDataToChange.findIndex((val) => val.SuCode === record?.unitCode);
        itemDataToChange[selectIndex] = { ...itemDataToChange[selectIndex], isActive: false };
        setSelectedItemData(itemDataToChange);
      } else {
        const removeSelect = itemDataToChange.filter((val) => val.SuCode !== record?.unitCode);
        setSelectedItemData(removeSelect);
        itemDataToChange = [...removeSelect];
      }
      const projData = ({
        ...projectStore,
        suTab: { selectedSu: selectedRows || [] },
        selectedSuItems: itemDataToChange,
      });
      dispatch(createProj(projData));
    }

    setSelectedSu(selectedRows);
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 200);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const setSuItemData = (data: any, suVal: any) => {
    const activeItem = data.map((val:any) => ({ ...val, isActive: true }));
    setSelectedItemData(activeItem);
    const projData = ({
      ...projectStore,
      itemsTab: { selectedItems: props?.itemDetails },
      suTab: { selectedSu: suVal },
      selectedSuItems: [...activeItem],
    });
    dispatch(createProj(projData));
    dispatch(prevProj(projData));
  };

  const setSellingUnit = (data: SellingUnit[]) => {
    const updatedData:any = data.map((item: SellingUnit, index) => ({ ...item, key: index }));
    const suData = updatedData?.filter(
      (val:SellingUnit) => val.parentUnitCode === selectedRU?.ruCode,
    );
    setSellingUnitsDisplay(suData);
    setSellingUnits(suData);
    dispatch(setSU(suData));
    if (isDraftProj() || isEditProject === true || isAcceptedProj()) {
      const oldSelectedKeys: React.Key[] = [];
      const oldSelectedSu: any = [];
      suData?.forEach((suList: any) => {
        suDetails.forEach((oldSU: any) => {
          if (suList?.unitCode === oldSU?.unitCode && oldSU.isAdded === true) {
            oldSelectedKeys.push(suList.key);
            oldSelectedSu.push(suList);
          }
        });
      });
      setSelectedRowKeys(oldSelectedKeys);
      setSelectedSu(oldSelectedSu);
      const projData = ({
        ...projectStore,
        itemsTab: { selectedItems: props?.itemDetails },
        suTab: { selectedSu: oldSelectedSu },
      });
      const suDataCode = suDetails?.filter((su: any) => su.isAdded === true)?.map(
        (val:any) => val.unitCode,
      );
      const suSplitPayload = {
        retailUnitProjectId: props?.projectId || props?.retailUnitProjectID,
        activeSuCode: suDataCode,
      };
      post(
        'get-su-split-items',
        suSplitPayload,
        (res: any) => setSuItemData(res, oldSelectedSu),
        () => {
          dispatch(createProj(projData));
          dispatch(prevProj(projData));
        },
      );
    }
  };

  const getSellingUnits = () => {
    get('selling-units', setSellingUnit);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: true,
    columnTitle: useTranslate('tab.items.select'),
    columnWidth: 60,
    onChange: onSelectChange,
    onSelect,
    getCheckboxProps: () => ({
      disabled: activeItems?.length <= 0,
    }),
    scrollToFirstRowOnChange: true,
  };

  const onSearch = (e:any, data:any) => {
    const searchFilterValues = sellingUnitsDisplay?.filter((sellingUnit: SellingUnit) => (
      (sellingUnit.unitName.toUpperCase().includes(data.value.toUpperCase())
      || (sellingUnit.unitCode.includes(data.value)))
    ));
    setSellingUnitsDisplay(searchFilterValues);
  };

  const onSearchClear = () => {
    setSellingUnitsDisplay(sellingUnits);
  };

  useEffect(() => {
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      setSelectedRU(data);
    });
  }, []);

  useEffect(() => {
    if (!isNullEmptyOrUndefined(selectedRU)) {
      getSellingUnits();
    }
  }, [selectedRU]);

  const onAddAllClick = () => {
    dispatch(setLoader(true));

    const selectedKeys: React.Key[] = [];
    const selectedData: any = [];

    const itemData: SuItemListData[] = sellingUnitsDisplay?.map((su: any) => {
      selectedKeys.push(su.key);
      selectedData.push(su);

      return {
        SuCode: su.unitCode,
        isActive: true,
        SuItemList: activeItems?.map(({ itemNo, itemType }: any) => ({
          ItemNo: itemNo,
          ItemType: itemType,
        })),
      };
    });

    onSelectChange(selectedKeys);
    setSelectedSu(selectedData);
    setSelectedItemData(itemData);

    const projData = {
      ...projectStore,
      selectedSuItems: itemData,
      suTab: { selectedSu: selectedData || [] },
    };
    dispatch(createProj(projData));

    setTimeout(() => {
      dispatch(setLoader(false));
    }, 2000);
  };

  const disableAddAll = () => (
    selectedRowKeys.length === sellingUnitsDisplay.length || activeItems?.length <= 0);

  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.leftWrapper}>
          <Search
            className={styles.search}
            id="search"
            placeholder={useTranslate('tab.su.search_su')}
            onSearch={onSearch}
            onChange={(e, data) => !data.value && onSearchClear()}
            onClear={onSearchClear}
          />
        </div>
        <Button
          className={styles.btAdAll}
          href=""
          text={`${useTranslate('tab.ru.add_all')} (${sellingUnitsDisplay.length})`}
          type="emphasised"
          onClick={onAddAllClick}
          disabled={disableAddAll()}
          small
        />
      </div>
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText="No Selling units available"
          icon={IcRU}
        />
      )}
      >
        <Table
          className="table-striped-rows suSplitTable"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={sellingUnitsDisplay}
          pagination={false}
          scroll={{ y: 'auto', x: `${activeItems?.length > 0 ? 'calc(100vw - 134px)' : 'unset'}` }}
          size="small"
          showHeader={sellingUnitsDisplay.length > 0}
        />
      </ConfigProvider>
    </div>
  );
};

export default SellingUnits;
