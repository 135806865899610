/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Accordion, { AccordionItem } from '@ingka/accordion';
import Carousel from '@ingka/carousel';
import Text from '@ingka/text';
import './ProjectItemPanel.css';
import Modal, { Sheets, ModalHeader, ModalBody } from '@ingka/modal';
import React from 'react';
import { useTranslate } from '../../../../common/services/translationService.js';
import { Item } from '../../createProject/items/Items';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

interface ProjectItemPanelProps {
    isVisible: boolean;
    connectedItem: Item | any | null,
    onClose: () => void;
}

const carouselProps = {
  id: 'ItemDetailsCarousel',
  hasNoControls: false,
  prefix: '',
  ariaLabelLeftBtn: 'See previous items',
  ariaLabelRightBtn: 'See next items',
  isSlideShow: true,
};

const ProjectItemPanel = ({
  isVisible, connectedItem, onClose,
}: ProjectItemPanelProps) => {
  const renderAccordionItems = (title: string, data: string, type?: string) => (
    <div className={type ? 'flexColumn itemContWidth' : 'flexRow itemContWidth'}>
      <Text className="item-date">
        {title}
        {title.includes(':') ? '' : ':'}
      </Text>
      <Text className="itemSubTitle">{data}</Text>
    </div>
  );

  return (
    <Modal
      visible={isVisible}
      focusLockProps={{
        disabled: false,
      }}
      handleCloseBtn={onClose}
    >
      <Sheets
        aria-label="Accessibility header for a modal"
        footer={null}
        header={
          <ModalHeader ariaCloseTxt="Close button text" title={useTranslate('inst.comp.itemDetailsTitle')} />
      }
      >
        <ModalBody>
          <Carousel {...carouselProps}>
            {connectedItem?.comparisonItemImageViewEntity?.map(
              (item:any) => <img key={item?.imagePath} className="item-image" src={item?.imagePath} alt="" />,
            )}
          </Carousel>
          <Text className="sectionTitle item-title">{connectedItem?.itemName}</Text>
          <div className="projectItemPanel-subTitle-container">
            <Text className="sectionSubTitle">
              {`${connectedItem?.paNo}  •  ${connectedItem?.itemNo}
            ${!isNullEmptyOrUndefined(connectedItem?.priceInclTax)
                ? `  •  ${!isNullEmptyOrUndefined(connectedItem?.currency)
                  ? `${connectedItem?.currency}`
                  : ''} ${connectedItem?.priceInclTax}`
                : ''}`}
            </Text>
            {connectedItem?.isBti === true ? (
              <div style={{ display: 'flex', marginLeft: '8px' }}>
                {'  •'}
                <BtiComponent ml={8} />
              </div>
            ) : null}
          </div>
          {!isNullEmptyOrUndefined(connectedItem?.deadlineDate) ? (
            <div className="flex-row">
              <Text className="sectionSubTitle">Deadline</Text>
              <Text className="item-date">
                {connectedItem?.deadlineDate}
              </Text>
            </div>
          ) : null }
          <Accordion>
            <AccordionItem
              id="itemDetailsDimensionMetric"
              title={useTranslate('itemDetails.dimensionMetric')}
              open
            >
              {connectedItem?.metricMeasureList?.map(
                (val:any) => renderAccordionItems(val.typeName, val.value),
              )}
            </AccordionItem>
          </Accordion>
          <Accordion>
            <AccordionItem
              id="itemDetailsMaterial"
              title={useTranslate('itemDetails.material')}
            >
              {connectedItem?.materialMeasureList?.map(
                (val:any) => renderAccordionItems(val.typeName, val.value, 'mat'),
              )}
            </AccordionItem>
          </Accordion>
          <Accordion>
            <AccordionItem
              id="itemDetailsDimensionImperial"
              title={useTranslate('itemDetails.dimensionImperial')}
            >
              {connectedItem?.imperialMeasureList?.map(
                (val:any) => renderAccordionItems(val.typeName, val.value),
              )}
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default ProjectItemPanel;
