/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import Text from '@ingka/text';
import React from 'react';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslate } from '../../../../../common/services/translationService.js';
import { Item } from '../../../createProject/items/Items';
import styles from './ProjectDetails.module.css';
import './ProjectDetails.css';
import Loader from '../../../../../common/components/loader/loader';
import { isRu, isSu } from '../../../../../common/services/commonService.js';

interface ProjectDetailModalProps {
    isVisible: boolean;
    onClose: () => void;
    projectDetails: any;
    showWarning: boolean;
    handleAcceptAndAssign: (projectId: any, isAccept: boolean, suProjectId?: string) => void;
}

const ProjectDetailModal = ({
  isVisible, onClose, projectDetails, showWarning, handleAcceptAndAssign,
}: ProjectDetailModalProps) => {
  const detailsTab: any = isRu() ? projectDetails?.gProjectDetails : projectDetails?.sellingUnitProjectBasicViewDto;
  const selectedItems: Item[] = isRu() ? projectDetails?.projectItemList : projectDetails?.sellingUnitProjectItemViewDto;
  const emptyMessage = useTranslate('create.proj.emptyMessage');
  const noticeDescription = useTranslate('create.proj.noticeDescription');
  const projectName = isRu() ? detailsTab?.GProjectName : detailsTab?.SellingUnitProjectName;
  const projectId:any = isRu() ? detailsTab?.GProjectId : detailsTab?.RetailUnitProjectId;
  const sellingUnitProjectId = isSu() ? detailsTab?.SellingUnitProjectId : '';

  const onModalClose = () => {
    onClose();
  };

  const columns: ColumnsType<Item> = [
    {
      key: 1,
      title: useTranslate('tab.items.paNo'),
      dataIndex: 'paNo',
      width: '10%',
      className: 'table-number',
      render: (text: any) => <div className="table-number">{text}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: useTranslate('tab.items.item_name'),
      dataIndex: 'itemName',
      width: '55%',
      render: (text: any, value: Item) => (
        <div className={styles.noticeContainer}>
          {value.isHighLighted && <SSRIcon className={styles.noticeIcon} paths={IcNotice} />}
          {text}
        </div>
      ),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: useTranslate('tab.items.type'),
      dataIndex: 'itemType',
      width: '15%',
      className: 'table-header-padding',
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: useTranslate('tab.items.item'),
      dataIndex: 'itemNo',
      width: '20%',
      className: 'table-number',
      render: (text: any) => <div className="table-number itemNo-common-design">{text}</div>,
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  return (
    <Modal
      visible={isVisible}
      focusLockProps={{
        disabled: true,
      }}
      handleCloseBtn={onModalClose}
      className="projectDetailsWrapper"
    >
      <Theatre
        className="projectDetails-theatre"
        aria-label="Accessibility header for a modal"
        header={<ModalHeader ariaCloseTxt="Close button text" title={projectName} />}
      >
        <div className={styles.projectDetailscontentWrapper}>
          <div className={styles.dateTitleWrapper}>
            <Text className={styles.sectionTitle}>
              {useTranslate('create.proj.startdate')}
            </Text>
            <Text className={styles.sectionTitle}>
              {useTranslate('create.proj.enddate')}
            </Text>
          </div>
          <div className={styles.dateWrapper}>
            <Text className={styles.date}>
              {detailsTab?.projectStartDate || 'Not Set'}
            </Text>
            <Text className={styles.date}>
              {detailsTab?.projectEndDate || 'Not Set'}
            </Text>
          </div>
          <div className={styles.dateNoticeContainer}>
            <SSRIcon className={styles.dateNoticeIcon} paths={IcNotice} />
            <Text className={styles.noticeText}>
              {useTranslate('project.details.dateHelperText')}
            </Text>
          </div>
          <Text className={styles.sectionTitle}>{useTranslate('create.proj.messageTitle')}</Text>
          {detailsTab?.message?.length > 0 ? (
            <Text className={styles.message}>{detailsTab?.message}</Text>
          ) : (
            <Text className={styles.noMessage}>{emptyMessage}</Text>
          )}
          { isSu() && (
            <>
              <Text className={styles.sectionTitle}>{useTranslate('create.proj.additionalMessageTitle')}</Text>
              {detailsTab?.additionalMessage?.length > 0 ? (
                <Text className={styles.message}>{detailsTab?.additionalMessage}</Text>
              ) : (
                <Text className={styles.noMessage}>{emptyMessage}</Text>
              )}
            </>
          )}
          <div className={styles.itemsHeader}>
            <div className={styles.itemTitleWrapper}>
              <Text className={styles.sectionTitle}>{`Items (${selectedItems?.length})`}</Text>
              {showWarning && (
              <div className={styles.noticeContainer}>
                <SSRIcon className={styles.noticeIcon} paths={IcNotice} />
                <Text className={styles.noticeText}>
                  {noticeDescription}
                </Text>
              </div>
              )}
            </div>
          </div>
          <Table
            className="table-striped-rows"
            columns={columns}
            dataSource={selectedItems}
            pagination={false}
            size="middle"
            rowKey={(record: any) => record?.itemNo}
          />
          <div className={styles.footer}>
            <Button
              className={styles.actionBtn}
              text={useTranslate('project.details.acceptTitle')}
              onClick={() => handleAcceptAndAssign(projectId, true, sellingUnitProjectId)}
            />
            <Button
              className={styles.actionBtn}
              text={isRu() ? useTranslate('project.details.assignTitle') : useTranslate('project.details.acceptAddComparsion')}
              type="primary"
              onClick={() => handleAcceptAndAssign(projectId, false, sellingUnitProjectId)}
            />
          </div>
        </div>
      </Theatre>
    </Modal>
  );
};

export default ProjectDetailModal;
