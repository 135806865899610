/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import React from 'react';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import IcDelete from '@ingka/ssr-icon/paths/trash-can';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import { UploadFile } from 'antd';
import { Moment } from 'moment';
import Button from '@ingka/button';
import { useTranslate } from '../../../../common/services/translationService.js';
import { SectionTitle } from '../InstantComparison';
import styles from './Summary.module.css';
import { Item } from '../../../desktop/createProject/items/Items.js';
import { isIos, isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';

interface SummaryItemProps {
  index: number;
  title: string;
  children: any;
  onEdit?: () => void;
  showError?: boolean;
  noEdit?: boolean;
}

const SummaryItem = ({
  index, title, children, onEdit, showError, noEdit,
}: SummaryItemProps) => (
  <div
    className={styles.summaryItemContainer}
    onClick={onEdit}
    onKeyDown={onEdit}
    tabIndex={index}
    role="button"
  >
    <div className={styles.summaryItemWrapper}>
      {showError === true ? (
        <div className={styles.warningTitleWrapper}>
          <div className={styles.icNotice}>
            <SSRIcon paths={IcNotice} />
          </div>
          <Text className={styles.title}>{title}</Text>
        </div>
      ) : <Text className={styles.title}>{title}</Text> }
      {children}
    </div>
    {noEdit !== true && (
    <div className={styles.editWrapper}>
      <Text className={styles.editText}>Edit</Text>
      <SSRIcon paths={IcRightArrow} />
    </div>
    )}
  </div>
);

interface SummaryProps {
  isEdit: boolean;
  onDeleteComparison: () => void;
  setFromSummary: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRu: any;
  selectedCompetitor: any;
  fileList: UploadFile[];
  competitorQuantity: number | undefined;
  competitorPrice: string | undefined;
  startDate: Moment | null;
  endDate: Moment | null;
  regularPrice: string | undefined;
  itemQuality: string | undefined;
  itemFunctionality: string | undefined;
  itemAssembled: boolean | undefined;
  connectedItem: Item | null;
  compItemMetric: any;
  setCurrentStep: (value: React.SetStateAction<number>) => void;
  maliciousURLError: boolean | undefined;
  maliciousFileError: boolean | undefined;
}

const Summary = ({
  isEdit, selectedRu, selectedCompetitor, competitorQuantity, fileList,
  competitorPrice, startDate, endDate, regularPrice,
  itemQuality, itemFunctionality, itemAssembled,
  connectedItem, compItemMetric, onDeleteComparison,
  setFromSummary, setCurrentStep, maliciousURLError, maliciousFileError,
}: SummaryProps) => {
  const onEditClick = (step: number) => {
    setFromSummary(true);
    setCurrentStep(step);
  };

  const showQuantity = () => `${competitorQuantity} ${compItemMetric}`;

  const deleteComp = () => {
    localStorage.setItem('deleteLoc', 'summary');
    onDeleteComparison();
  };

  return (
    <div className={styles.sectionWrapper}>
      {connectedItem ? (
        <>
          <Text className={styles.itemName}>{connectedItem?.itemName}</Text>
          <Text className={styles.itemType}>
            {`${connectedItem.itemType} | ${connectedItem.itemNo}`}
          </Text>
        </>
      ) : null}
      <div className={styles.icActionContainer}>
        <SectionTitle sectionTitle={useTranslate('inst.comp.summary')} />
        {isEdit ? (
          <Button
            className={styles.btDeleteComparison}
            text="Delete"
            ssrIcon={IcDelete}
            type="secondary"
            onClick={deleteComp}
            small
          />
        ) : null}
      </div>
      {isIos() && (
      <SummaryItem
        index={9}
        title="Retail unit"
        noEdit
      >
        <Text className={styles.subTitle}>{selectedRu?.ruName}</Text>
      </SummaryItem>
      )}
      <SummaryItem
        index={0}
        title="Competitor"
        onEdit={() => onEditClick(1)}
      >
        <Text className={styles.subTitle}>{selectedCompetitor}</Text>
      </SummaryItem>
      <SummaryItem
        index={1}
        title="Photos uploaded"
        onEdit={() => onEditClick(2)}
        showError={maliciousFileError}
      >
        <Text className={styles.subTitle}>
          {fileList.length}
        </Text>
      </SummaryItem>
      <SummaryItem
        index={2}
        title="Quantity"
        onEdit={() => onEditClick(3)}
      >
        <Text className={styles.subTitle}>
          {showQuantity()}
        </Text>
      </SummaryItem>
      <SummaryItem
        index={3}
        title="Competitor Price"
        onEdit={() => onEditClick(4)}
      >
        <Text className={styles.subTitle}>{competitorPrice}</Text>
        {!isNullEmptyOrUndefined(startDate) ? (
          <>
            <Text className={styles.subTitle}>Time restricted offer start:</Text>
            <Text className={styles.subTitle}>{startDate?.format('YYYY-MM-DD')}</Text>
          </>
        ) : null}
        {!isNullEmptyOrUndefined(endDate) ? (
          <>
            <Text className={styles.subTitle}>Time restricted offer end:</Text>
            <Text className={styles.subTitle}>{endDate?.format('YYYY-MM-DD')}</Text>
          </>
        ) : null}
      </SummaryItem>
      {regularPrice ? (
        <SummaryItem
          index={4}
          title="Regular price"
          onEdit={() => onEditClick(4)}
        >
          <Text className={styles.subTitle}>{regularPrice}</Text>
        </SummaryItem>
      ) : null}
      <SummaryItem
        index={5}
        title="Item quality"
        onEdit={() => onEditClick(8)}
      >
        <Text className={styles.subTitle}>{`Its ${itemQuality}`}</Text>
      </SummaryItem>
      <SummaryItem
        index={6}
        title="Item functionality"
        onEdit={() => onEditClick(9)}
      >
        <Text className={styles.subTitle}>{`Its ${itemFunctionality}`}</Text>
      </SummaryItem>
      <SummaryItem
        index={7}
        title="Assembly"
        onEdit={() => onEditClick(10)}
      >
        <Text className={styles.subTitle}>{`Assembly ${itemAssembled ? '' : 'not '}required`}</Text>
      </SummaryItem>
      <SummaryItem
        index={8}
        title="Optional fields"
        onEdit={() => onEditClick(11)}
        showError={maliciousURLError}
      >
        <Text className={`${styles.subTitle} ${styles.ellipsis}`}>
          Material, Sustainability, Comment, Item Name, Item Url
        </Text>
      </SummaryItem>
    </div>
  );
};

export default Summary;
