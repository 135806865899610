/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import Modal, {
  ModalBody, ModalFooter, ModalHeader, Sheets,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import React from 'react';
import plus from '@ingka/ssr-icon/paths/plus';
import Text from '@ingka/text';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { useNavigate } from 'react-router-dom';
import './ItemDetailsMobile.css';
import moment from 'moment';
import { useTranslate } from '../../../../common/services/translationService.js';
import { appRoutes } from '../../../../common/constants/constant';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

interface ItemDetailsMobileProps {
  openItemModal: boolean;
  selectedItem: any;
  isInstantComparison?: boolean;
  onCloseItemModal: () => void;
}

const ItemDetailsMobile = ({
  openItemModal, selectedItem, isInstantComparison,
  onCloseItemModal,
}: ItemDetailsMobileProps) => {
  const navigate = useNavigate();

  const carouselProps = {
    id: 'ItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const renderAccordionItems = (title: string, data: string, type?: string) => (
    <div key={`${type}${title}${data}`} className={type ? 'flexColumn itemContWidth' : 'flexRow itemContWidth'}>
      <Text className="item-date">
        {title}
        {title?.includes(':') ? '' : ':'}
      </Text>
      <Text className="itemSubTitle">{data}</Text>
    </div>
  );

  return (
    <Modal
      visible={openItemModal}
      handleCloseBtn={() => onCloseItemModal()}
    >
      <Sheets
        aria-labelledby="itemDetailModal"
        alignment="right"
        fullSize
        size="small"
        header={(
          <ModalHeader
            className="defaultFont"
            titleId="itemDetailModal"
            title="Item Details"
            floating={false}
          />
          )}
        footer={(
          isInstantComparison !== false && (
          <ModalFooter>
            <Button
              text="Add Comparison"
              type="emphasised"
              small={false}
              ssrIcon={plus}
              disabled={
                moment(selectedItem?.itemInfos?.deadlineDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                || moment(selectedItem?.itemInfos?.itemStartDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
              }
              onClick={() => {
                navigate(
                  appRoutes.IC,
                  { state: { isInstant: false, selectedItem, from: 'itemDetails' } },
                );
                onCloseItemModal();
              }}
            />
          </ModalFooter>
          ))}
      >
        <ModalBody>
          <Carousel {...carouselProps}>
            <img key="item-img" className="item-image" src={selectedItem?.itemInfos?.imgData} alt="" />
          </Carousel>
          <Text className="sectionTitle item-title">{selectedItem?.itemName}</Text>
          <div className="itemDetailsMobile-subTitle-container">
            <Text className="sectionSubTitle">
              {`${selectedItem?.paNo}  •  ${selectedItem?.itemNo}${!isNullEmptyOrUndefined(selectedItem?.itemInfos?.priceInfo?.priceInclTax) ? `  •  ${selectedItem?.itemInfos?.priceInfo?.currencyCode} ${selectedItem?.itemInfos?.priceInfo?.priceInclTax}` : ''}`}
            </Text>
            {selectedItem?.isBti === true ? (
              <div style={{ display: 'flex', marginLeft: '8px' }}>
                {'  •'}
                <BtiComponent ml={8} />
              </div>
            ) : null}
          </div>
          <div className="flex-row">
            <Text className="sectionSubTitle">Deadline</Text>
            <Text className="item-date">
              {selectedItem?.itemInfos?.deadlineDate}
            </Text>
          </div>
          <Accordion>
            <AccordionItem
              id="itemDetailsDimensionMetric"
              title={useTranslate('itemDetails.dimensionMetric')}
            >
              {selectedItem?.itemInfos?.metricMeasures?.map(
                (val:any) => renderAccordionItems(val.typeName, val.value),
              )}
            </AccordionItem>
          </Accordion>
          <Accordion>
            <AccordionItem
              id="itemDetailsMaterial"
              title={useTranslate('itemDetails.material')}
            >
              {selectedItem?.itemInfos?.materials?.map(
                (val:any) => renderAccordionItems(val.typeName, val.value, 'mat'),
              )}
            </AccordionItem>
          </Accordion>
          <Accordion>
            <AccordionItem
              id="itemDetailsDimensionImperial"
              title={useTranslate('itemDetails.dimensionImperial')}
            >
              {selectedItem?.itemInfos?.imperialMeasures?.map(
                (val:any) => renderAccordionItems(val.typeName, val.value),
              )}
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

ItemDetailsMobile.defaultProps = {
  isInstantComparison: 'true',
};

export default ItemDetailsMobile;
