/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Modal, {
  ModalBody, ModalHeader, Theatre,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import '@ingka/zoom-image/dist/style.css';
import Text from '@ingka/text';
import SortUpIcon from '@ingka/ssr-icon/paths/sort-ascending';
import SortDownIcon from '@ingka/ssr-icon/paths/sort-descending';
import SSRIcon from '@ingka/ssr-icon';
import IcItems from '@ingka/ssr-icon/paths/copy';
import { Table, ConfigProvider } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ZoomImage from '@ingka/zoom-image';
import moment from 'moment';
import './ComparisonList.css';
import { useAppDispatch } from '../../../../store/hooks';
import { setLoader } from '../../../../store/reducers/create-proj-reducer';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { useTranslate } from '../../../../common/services/translationService.js';
import {
  projectType, isNullEmptyOrUndefined, userData, isRu, isSu, userRu,
} from '../../../../common/services/commonService.js';
import Pagination from '../../../../common/components/pagination/Pagination';
import useCallApi from '../../../../common/services/apiService.js';

interface ComparisonListProps {
  headerHeight: number;
  searchResults: any[];
  isLoading: boolean;
  showMoreContent: () => void;
  sortConfig: any;
  paginationItemCount: number;
  totalComparisonCount: number;
  handleTableChange: (pagination: any, filters: any, sorter: any, extra: any) => void;
  comparisonClick: (record: any) => void;
}

const fetchUserId = () => userData()?.userId || null;

const fetchIsSuperAdmin = () => userData()?.isSuperAdmin;

export const fectchUnitCode = () => {
  if (isRu()) {
    return userRu();
  }
  if (isSu()) {
    return localStorage.getItem('RuCodeForSu');
  }
  return null;
};

export const comparisonPayload: any = {
  ItemName: '',
  ItemNo: '',
  FromDate: null,
  ToDate: null,
  HfbNos: [],
  PraNos: [],
  PaNos: [],
  UserId: null,
  UnitCode: null,
  ProjectType: projectType(),
  ItemQuality: null,
  ItemFunctionality: null,
  Photos: null,
  OnlinePrice: null,
  CompetitorIds: [],
  NoCompetition: 2,
  CompetitorUnitCodes: [],
  RuCodes: [],
  SuCodes: [],
  RocNos: [],
  SpNos: [],
  PcNos: [],
  StartWith: 0,
  TotalCount: 25,
  CurrentUserId: fetchUserId(),
  IsMobile: true,
  SortEntity: 'ComparedOn',
  TypeOfSort: 'desc',
  IsSuperAdmin: fetchIsSuperAdmin(),
  SellingPrice: [],
  RecSalesPrice: [],
  ProjectId: '',
};

export interface Comparison {
  key: React.Key,
  projectId: number,
  projectType: string,
  competitorName: string,
  userId: string,
  updatedUserId: string,
  comparedByText: string,
  ruCode: string,
  ruName: string,
  suCode: string,
  suName: string,
  ruText: string,
  currentUserProjectType: number,
  currentUserId: string,
  comparedOn: Date,
  updatedOn: Date,
  comparedOnDateText: string,
  suComparedOnText: string,
  comparedOnText: string,
  comparedBy: string,
  updatedOnText: string,
  itemConnectionMaxDays:number,
  updatedOnNoItemConnectedText:string,
  itemName:string,
  itemType:string,
  paNo:string,
  praNo:string,
  hfbNo:string,
  troStartDate:string,
  troEndDate:string,
  competitorItemPriceLocalText:string,
  competitorItemPrice:string,
  competitorItemPriceLocal:string,
  ispLocalText:string,
  isp:string,
  ispLocal:string,
  competitorItemUrl:string,
  multipack:string,
  rspValue:any,
  rspCurrencyCode:string,
  rsp:string,
  rspText:string,
  ikeaMultiPack:number,
  diffRspPriceText:string,
  diffLocalPriceText:string,
  diffRspPriceStyle:string,
  diffLocalPriceStyle:string,
  rupIosEndDate:string,
  rupEndDate:string,
  supIosEndDate:string,
  supRuEndDate:string,
  supEndDate:string,
  noCompetition:number,
  isInstantCompare:number,
  isEdit:boolean,
  isDelete:boolean,
  photos:number,
  recSalePricePct:string,
  salePricePct:string,
  multipack_Int:number,
  isFoodHfb: boolean,
  ikeaPriceUnitText: string,
  ikeaPriceUnitValue: string,
  ikeaNoOfUnits: string,
  compPriceUnitText: string,
  compPriceUnitValue: string,
  compNoOfUnits: string,
  ispLocal_Int:string,
  competitorItemPriceLocal_Int:any,
  currencyCode:string,
  expressDeliveryPriceLocal:string,
  curbsideDeliveryPriceLocal:string,
  roomOfChoiceDeliveryPriceLocal:string,
  onlinePrice:string,
  rspPerPiece:string,
  ispPerPiece:string,
  ispLocalPerPiece:string,
  competitorItemPriceLocalPerPiece:string,
  competitorItemPricePerPiece:string,
  isExcel:boolean,
  comparisonProjectId:number,
  timeRestrictedOffer:string,
  regularPriceLocal:string,
  assemblyRequired:string,
  regularPrice:string,
  quality:string,
  functionality:string,
  material:string,
  sustainability:string,
  comment:string,
  competitorItemName:string,
  _itemNo:string,
  itemNo:string,
}

export const ComparisonList = ({
  headerHeight, searchResults, isLoading, showMoreContent, sortConfig,
  totalComparisonCount, handleTableChange, comparisonClick,
}: ComparisonListProps) => {
  const emptyText = useTranslate('find.comp.searchEmptyText');
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [imageToken, setImageToken] = useState<any>();
  const dispatch = useAppDispatch();
  const { post, get } = useCallApi();

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const getSortOrder = (dataIndex: string) => {
    if (sortConfig.sortEntity === dataIndex) {
      return sortConfig.typeOfSort;
    }
    return null;
  };

  const renderTableTitle = (title: string, dataIndex: string) => {
    const isActive = dataIndex === sortConfig.sortEntity;
    return (
      isActive ? (
        <div style={{ display: 'flex' }}>
          {title}
          {sortConfig.typeOfSort === 'ascend' ? (
            <SSRIcon className="sortIcon" paths={SortUpIcon} />
          ) : (
            <SSRIcon className="sortIcon" paths={SortDownIcon} />
          )}
        </div>
      ) : (
        <div>{title}</div>
      )
    );
  };

  const renderROrGDot = (text: any) => {
    const value = text?.toString();
    const isNegative = value?.charAt(0) === '-';
    return (
      value ? (
        <div className="fcPercentageWrapper">
          <div className={isNegative ? 'fcRedDot' : 'fcGreenDot'} />
          {text}
        </div>
      ) : (
        <div className="fcPercentageWrapper">
          N/A
        </div>
      )
    );
  };

  const renderImage = (text: any) => {
    const isText = !isNullEmptyOrUndefined(text);
    return (
      isText ? (
        <div className={`table-number ${text !== 0 ? 'fcSearchResultsImage' : ''}`}>
          {text}
        </div>
      ) : (
        <div className="table-number">
          {text || 'N/A'}
        </div>
      )
    );
  };

  const renderCompetitorPrice = (text: any, record: any) => {
    const isText = isNullEmptyOrUndefined(record.competitorItemPriceLocal);
    const isNoComp = record.noCompetition === 1;
    return (
      isNoComp ? (<div className="table-number">No competition</div>)
        : isText ? (
          <div className="table-number">N/A</div>
        ) : (
          <div className="table-number">
            {text}
          </div>
        )
    );
  };

  const renderPagination = () => {
    const count = searchResults.length;
    const totalCount = totalComparisonCount;
    return (
      <Pagination
        count={count}
        isLoading={isLoading}
        totalCount={totalCount}
        showMoreContent={showMoreContent}
      />
    );
  };

  const onCloseItemModal = () => {
    setOpenImageModal(false);
  };

  const carouselProps = {
    id: 'fcItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const isEditRDelete = (record: any) => {
    comparisonClick(record);
  };

  const getImages = (record: any) => {
    if (isNullEmptyOrUndefined(record.photos) || record.photos === 0) {
      isEditRDelete(record);
    } else {
      dispatch(setLoader(true));
      getSasToken();
      const payload = {
        ComparisonId: record.comparisonProjectId,
        ItemNo: record.itemNo,
        ProjectType: record.projectType,
        ItemType: record.itemType,
      };
      post('get-photos', payload, (data: any) => {
        setSelectedItem(data);
        setOpenImageModal(true);
        dispatch(setLoader(false));
      }, () => {
        dispatch(setLoader(false));
      });
    }
  };

  const columns: ColumnsType<Comparison> = [
    {
      key: 1,
      title: renderTableTitle('PA', 'paNo'),
      dataIndex: 'paNo',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('paNo'),
      width: '5%',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: renderTableTitle('Item #', 'itemNo'),
      dataIndex: 'itemNo',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemNo'),
      width: '9%',
      render: (text: any) => <div className={`${!isNullEmptyOrUndefined(text) ? 'itemNo-common-design' : ''}`}>{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: renderTableTitle('Item name', 'itemName'),
      dataIndex: 'itemName',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('itemName'),
      width: '16%',
      render: (text: any) => <div>{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: renderTableTitle('Competitor', 'competitorName'),
      dataIndex: 'competitorName',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('competitorName'),
      width: '9%',
      render: (text: any) => <div>{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: renderTableTitle('IKEA sales price', 'ispLocalText'),
      dataIndex: 'ispLocalText',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('ispLocalText'),
      width: '8%',
      className: 'table-number',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 6,
      title: renderTableTitle('Competitor price', 'competitorItemPriceLocalText'),
      className: 'table-number',
      dataIndex: 'competitorItemPriceLocalText',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('competitorItemPriceLocalText'),
      width: '10%',
      render: (text: any, record: any) => renderCompetitorPrice(text, record),
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 7,
      title: renderTableTitle('IKEA quantity & unit', 'ikeaNoOfUnits'),
      className: 'table-number',
      dataIndex: 'ikeaNoOfUnits',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('ikeaNoOfUnits'),
      width: '8%',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 8,
      title: renderTableTitle('Competitor quantity & unit', 'compNoOfUnits'),
      className: 'table-number',
      dataIndex: 'compNoOfUnits',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('compNoOfUnits'),
      width: '9%',
      render: (text: any) => <div className="table-number">{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 9,
      title: renderTableTitle(`${useTranslate('tab.items.image')}`, 'photos'),
      className: 'table-number',
      dataIndex: 'photos',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('photos'),
      width: '5%',
      render: (text: any) => renderImage(text),
      onCell: (record) => ({
        onClick: () => getImages(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 10,
      title: renderTableTitle('RSP %', 'recSalePricePct'),
      className: 'fcLeftPadding',
      dataIndex: 'recSalePricePct',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('recSalePricePct'),
      width: '7%',
      render: (text: any) => renderROrGDot(text),
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 11,
      title: renderTableTitle('SP %', 'salePricePct'),
      dataIndex: 'salePricePct',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('salePricePct'),
      width: '6%',
      render: (text: any) => renderROrGDot(text),
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 12,
      title: renderTableTitle('Compared By', 'comparedByText'),
      dataIndex: 'comparedByText',
      className: 'fcPadding',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('comparedByText'),
      width: '5%',
      render: (text: any) => <div className="table-text">{text || 'N/A'}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 13,
      title: renderTableTitle('Compared On', 'comparedOn'),
      dataIndex: 'comparedOn',
      className: 'table-number',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder: getSortOrder('comparedOn'),
      width: '10%',
      render: (text: any) => <div className="table-text">{moment(text).format('YYYY-MM-DD')}</div>,
      onCell: (record) => ({
        onClick: () => isEditRDelete(record),
      }),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  return (
    <div className="searchResultsWrapper" style={{ height: `calc(100vh - ${headerHeight}px)` }}>
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText={emptyText}
          icon={IcItems}
          subText
        />
      )}
      >
        {/* <div className="pills-container">
          <div className="pills-wrapper" />
          <Button
            className="btn_edit"
            href=""
            text="Edit"
            disabled
            type="emphasised"
            onClick={() => null}
            small
          />
        </div> */}
        <Table
          className="table-striped-rows fcTable"
          columns={columns}
          dataSource={searchResults}
          pagination={false}
          size="small"
          rowKey={(record: any) => record?.itemNo}
          onChange={handleTableChange}
          showHeader={searchResults?.length > 0}
          scroll={searchResults?.length > 0 ? { y: 'calc(100vh - 370px)' } : { y: '' }}
          footer={undefined}
        />
        {searchResults.length > 0 && totalComparisonCount > 25
        && renderPagination()}
        <Modal
          visible={openImageModal}
          handleCloseBtn={() => onCloseItemModal()}
        >
          <Theatre
            aria-labelledby="fcImageDetailsModal"
            className="fcImageDetails-theatre"
            header={(
              <ModalHeader
                className="defaultFont"
                titleId="fcImageDetailsModal"
                title="Images"
                floating={false}
              />
          )}
          >
            <ModalBody>
              <div className="fcImgeContainer">
                <div className="fcIkeaImage">
                  <Text className="fcImageTitleText">IKEA product images</Text>
                  <Carousel {...carouselProps}>
                    {selectedItem?.ikeaPhotosList?.map(
                      (item:any) => (
                        <ZoomImage
                          src={item?.imagePath}
                        >
                          <img
                            key={item?.imagePath}
                            className="fcItemDetails-item-image"
                            src={item?.imagePath}
                            alt=""
                          />
                        </ZoomImage>
                      ),
                    )}
                  </Carousel>
                </div>
                <div className="fcCompImage">
                  <Text className="fcImageTitleText">Competitor product images</Text>
                  <Carousel {...carouselProps}>
                    {selectedItem?.competitorPhotosList?.map(
                      (item:any) => (
                        <ZoomImage
                          src={item?.imagePath.concat(imageToken?.sasToken)}
                        >
                          <img
                            key={item?.imagePath}
                            className="fcItemDetails-item-image"
                            src={item?.imagePath.concat(imageToken?.sasToken)}
                            alt=""
                          />
                        </ZoomImage>
                      ),
                    )}
                  </Carousel>
                </div>
              </div>
            </ModalBody>
          </Theatre>
        </Modal>
      </ConfigProvider>
    </div>
  );
};
