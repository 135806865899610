/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Cards from '../../../../common/components/card/Card';
import './Sent.css';
import {
  isIos, isRu, isNullEmptyOrUndefined, isSu,
} from '../../../../common/services/commonService.js';
import {
  ProjectType, commonColumns, iosEndDate, ruSuAccepted,
} from '../Dashboard';
import DashboardList from '../../../../common/components/list/List';
import { useAppSelector } from '../../../../store/hooks';

interface SentProps {
    sentProjects: any[];
    highlightDays: number | null;
    handleProjectClick: (value: any, type: ProjectType) => void;
}

const Sent = ({
  sentProjects, highlightDays, handleProjectClick,
}: SentProps) => {
  const [columnData, setColumnData] = useState<any[]>([]);
  const gridListFlag = useAppSelector((state) => state?.dashboardReducer?.gridListFlag);
  const showIosEndDate = (project: any) => {
    if (isIos()) {
      return false;
    }
    return (isNullEmptyOrUndefined(project.parentProjectEndDate) ? false
      : project.parentProjectEndDate);
  };

  const buildColumnData = () => {
    const tempColumnData = [...commonColumns];
    if (isIos() === true || isRu() === true) {
      tempColumnData.push(ruSuAccepted);
      setColumnData(tempColumnData);
    }
    if (isRu() === true) {
      tempColumnData.splice(4, 0, iosEndDate);
      setColumnData(tempColumnData);
    }
    if (isSu()) {
      setColumnData(tempColumnData);
    }
  };

  useEffect(() => {
    buildColumnData();
  }, []);

  const setHeight = (project: any) => {
    if (isIos()) {
      return '154px';
    }
    if (isRu()) {
      return '188px';
    }
    return (isNullEmptyOrUndefined(project.parentProjectEndDate) ? '120px' : '154px');
  };

  return (
    <div>
      {sentProjects?.length > 0
        ? gridListFlag === true
          ? (
            <div className="projectsContentWrapper">
              {sentProjects.map((project) => (
                <Cards
                  key={project.projectId}
                  cardKey={project.projectId}
                  name={project.projectName}
                  images={project?.itemImageUrl}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={showIosEndDate(project)}
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  highlightDays={highlightDays}
                  suAccepted={isNullEmptyOrUndefined(project.accepted) ? false : project.accepted}
                  onClick={() => handleProjectClick(project, ProjectType.SENT)}
                />
              ))}
            </div>
          ) : (
            <DashboardList
              projectData={sentProjects}
              columnData={columnData}
              onRowClick={handleProjectClick}
            />
          ) : null}
    </div>
  );
};

export default Sent;
