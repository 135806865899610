import { configureStore } from '@reduxjs/toolkit';
import projReducer from './reducers/create-proj-reducer';
import dashboardReducer from './reducers/dashboard-reducer';
import findCompReducer from './reducers/find-comp-reducer';

const store = configureStore({
  reducer: {
    createProjReducer: projReducer,
    dashboardReducer,
    findCompReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
